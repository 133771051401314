import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from "react-uuid"

import {
    Grid
} from "@material-ui/core"

import {
    getUser,
    getUserDetail,
    putUser,
    postUser,
} from "./api/UserApi"

import OfficeList from "./components/OfficeList"
import UserList from "./components/UserList"

const User = memo(({ }) => {
    const [userList, setUserList] = useState([{
        id: 0,
        name: "Juan",
        uniqueKey: uuid(),
    }]);
    const [officeList, setOfficeList] = useState([
        {
            id: 0,
            name: "Colinas de bello monte",
            active: false,
            uniqueKey: uuid(),
        },
        {
            id: 1,
            name: "Los palos grandes",
            active: false,
            uniqueKey: uuid(),
        },
        {
            id: 2,
            name: "El valle",
            active: false,
            uniqueKey: uuid(),
        },
        {
            id: 3,
            name: "Caurimare",
            active: false,
            uniqueKey: uuid(),
        }
    ]);
    const [userRoleList, setUserRolList] = useState([]);
    const [userInfo, setUserInfo] = useState({
        id: "",
        name: "",
        active: false,
        role: ""
    })

    useEffect(() => {
        const fnGetUser = async () => {
            const response = await getUser();

            if (response.status === 200) {

            }
        }

        //fnGetUser();
    }, [])

    const fnOnClickUserList = useCallback(async (event) => {
        try {
            const userId = event.currentTarget.getAttribute('data-id')
            const response = await getUserDetail(userId);

            if (response.status === 200) {

            }

        } catch (error) {
            console.error('fnOnClickUserList => ', error)
        }
    })

    const fnOnClickOfficeList = useCallback((name, value, uniqueKey) => {
        try {
            console.log(name, value, uniqueKey)
            setOfficeList(officeList => officeList.map(x => {
                if (x.uniqueKey === uniqueKey) {
                    return {
                        ...x,
                        active: value
                    }
                }
                return x
            }))

        } catch (error) {
            console.error('fnOnClickOfficeList => ', error)
        }
    }, [])

    const fnOnSave = async () => {
    }

    return (
        <Grid container alignContent="flex-start" alignItems="center">
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                        <UserList data={userList} onClick={fnOnClickUserList} />
                    </Grid>
                    <Grid item xs={6} sm={7} md={8} lg={9} xl={9}>
                        <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs={6} sm={7} md={8} lg={9} xl={9}>
                                <Grid container alignContent="flex-start" alignItems="center" spacing={1}>

                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                                <OfficeList data={officeList} onClick={fnOnClickOfficeList} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
        </Grid>
    );
});

User.propTypes = {

};

export default User;