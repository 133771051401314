import {
    MAPS_INITLOCATION,
    MAPS_ADDMARKERS,
    MAPS_REMMARKERS,
    MAPS_ADDPOLYGON,
    MAPS_SETLOCATIONINFO,
    MAPS_SETVALUE,
    MAPS_SETDEFAULTINFO,
    MAPS_REMPOLYGON,
    MAPS_SETOPTIONMENU,
    MAPS_SETOPTIONMENUVALUE,
} from "./MapsContants";

export const initLocation = (location) => ({ type: MAPS_INITLOCATION, location });
export const addMarkers = (id, location) => ({ type: MAPS_ADDMARKERS, id, location });
export const remMarkers = (id) => ({ type: MAPS_REMMARKERS, id });
export const addPolygon = (id, location) => ({ type: MAPS_ADDPOLYGON, id, location });
export const setLocationInfo = (info) => ({ type: MAPS_SETLOCATIONINFO, info });
export const setWidth = (value) => ({ type: MAPS_SETVALUE, id: 'width', value })
export const setHeight = (value) => ({ type: MAPS_SETVALUE, id: 'height', value })
export const setDefaultInfo = (latLng, polygone) => ({ type: MAPS_SETDEFAULTINFO, latLng, polygone })
export const remPolygon = () => ({ type: MAPS_REMPOLYGON });
export const setOptionMenu = (data) => ({ type: MAPS_SETOPTIONMENU, data })
export const setOptionMenuValue = (id) => ({ type: MAPS_SETOPTIONMENUVALUE, id })