import {
    SNACKBAR_OPEN,
    SNACKBAR_CLOSE,
    SNACKBAR_OPENAPI,
} from './SnackbarConstants';

export const snackbarSuccess = (message) => ({ type: SNACKBAR_OPEN, variant: 'success', message });
export const snackbarWarning = (message) => ({ type: SNACKBAR_OPEN, variant: 'warning', message });
export const snackbarError = (message) => ({ type: SNACKBAR_OPEN, variant: 'error', message });
export const snackbarClose = () => ({ type: SNACKBAR_CLOSE });
export const snackbarApi = (statusCode, data) => ({ type: SNACKBAR_OPENAPI, statusCode, data });