import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';

import {
    Grid, BottomNavigationAction,
} from "@material-ui/core/";
import MapsContainer from '../components/Maps/Maps';
import Register from '../containers/Register/Register';
import { useQueryParam, StringParam } from 'use-query-params';
import Unauthorized from '../components/unauthorized/Unauthorized';
import { LoaderContext } from "../components/Loader/LoaderContext";
import { fnGetUrl, fnPostUrl } from '../functions/Api';
import User from "../containers/User/User"
import { SnackbarContext } from '../components/snackbar/SnackbarContext';

const mapSizesToProps = ({ width, height }) => ({
    width: width,
    height: height,
})

const getValidateKey = (token) => {
    return {
        endpoint: "api/Admin/ValidateKey/Key/" + token,
        useBasic: true,
    }
}

const Main = React.memo(({ width, height }) => {
    const [key, setKey] = useQueryParam('Key', StringParam);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { loaderState, setLoader } = useContext(LoaderContext);
    const { sbDispatch } = useContext(SnackbarContext)
    const [tgId, setTgId] = useState(0);
    const [registerType, setRegisterType] = useState("CREATE")
    const [infoSucu, setInfoSucu] = useState(undefined)
    const [id, setId] = useState(0);

    useEffect(() => {
        const getValidToken = async () => {
            setLoader(true);
            const response = await fnGetUrl(getValidateKey(key));
            try {
                if (response.status === 200) {
                    
                    if (response.data.errorCode === 0) {
                        const responseValidateKey = response.data.data
                        switch (responseValidateKey.type) {
                            case "CREATE":
                            case "EDIT":
                                setTgId(responseValidateKey.tgid)
                                setIsAuthorized(true)
                                if (responseValidateKey.type === "EDIT") {
                                    setInfoSucu(responseValidateKey.sucu)
                                }
                                setId(responseValidateKey.id)
                                break;
                            case "USER":
                                setIsAuthorized(true)
                            default:
                        }

                        setRegisterType(responseValidateKey.type)
                    }else{
                        sbDispatch.error(response.data.message)
                    }
                }else{
                    sbDispatch.error(response.data.message)
                }
            } catch (error) {

            }
            setLoader(false);
        }

        if (key !== undefined) {
            getValidToken();
        }
    }, [key])

    return (
        (isAuthorized)
            ? (registerType === "USER")
                ? < User />
                : <Grid container
                    alignContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                    style={{ height: height }}>
                    <Grid item md={7} lg={8} xl={9} style={{ height: height }}>
                        <MapsContainer
                            width="100%"
                            height="100%"
                            addressInformation={registerType === "CREATE"}
                            addCurrentLocation={registerType === "CREATE"}
                            defaultInfo={infoSucu} />
                    </Grid>
                    <Grid item md={5} lg={4} xl={3} style={{ height: height }}>
                        <Register token={key} tgId={tgId} defaultInfo={infoSucu} id={id} />
                    </Grid>
                </Grid>
            : loaderState === false ? <Unauthorized /> : ''               
    );
});

Main.propTypes = {

};

export default withSizes(mapSizesToProps)(Main);