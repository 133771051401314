import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const EditImagesIcon = () => {
    return (
        <SvgIcon viewBox="0 -2 6 9">
            <path 
                className="st0" 
                d="M5.5,5.2c0,0,0,0.1,0,0.1c0,0.1,0.2,0.2,0.3,0.1l0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.2,0.1L5,6.1L5.2,5
                c0,0,0,0.1,0,0.1C5.3,5.1,5.3,5.2,5.5,5.2L5.5,5.2z M6.7,3.3L5.4,4.8c0,0.1,0,0.1,0,0.2l0,0c0,0,0.1,0,0.2,0l1.3-1.6L6.7,3.3z
                M7.4,3.8L6,5.4c0,0.1,0,0.1,0,0.2l0,0c0,0,0.1,0,0.2,0L7.5,4L7.4,3.8z M7,3.5L5.7,5c-0.1,0.1-0.1,0.2,0,0.3l0,0
                c0.1,0.1,0.2,0,0.2-0.1l1.3-1.5L7,3.5z M7.6,3.9L7.6,3.9c0.2-0.2,0.2-0.4,0.1-0.5L7.3,3C7.2,2.9,7,2.9,6.8,3.1L6.8,3.2L7.6,3.9z
                M5.3,0h-5C0.1,0,0,0.1,0,0.3v5.4C0,5.9,0.1,6,0.3,6l4.6,0l0,0L5,5.5h0V5.4l0.1-0.5l0.4-0.4l0-0.1V0.3C5.5,0.1,5.4,0,5.3,0z
                M4.1,0.9c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4C3.7,1.1,3.9,0.9,4.1,0.9z M0.8,5
                c0.4-0.7,0.8-1.4,1.1-2.1C2.4,3.3,3,3.7,3.5,4.1C3.7,4,3.8,3.8,4,3.7c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0
                c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0,0,0,0.1c0,0.3,0,0.7,0,1C3.4,5,2.1,5,0.8,5z"/>
        </SvgIcon>
    )
}

export default EditImagesIcon