import React, { useEffect, memo, useContext, useState, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';

import {
    Grid
} from "@material-ui/core/"
import { LoaderContext } from '../../components/Loader/LoaderContext';
import { MapsStateContext } from '../../components/Maps/MapsContext';
import TaTextField from "../../components/textfield/TaTextField";
import TaFab from "../../components/fab/TaFab";

import RegisterReducer from "./RegisterReducer"

import {
    REGISTER_INIT,
} from "./RegisterConstants"

import {
    setValue,
    setListStates,
    setListMunicipalities,
    setCurrentState,
    setCurrentMunicipality,
    errorSetStates,
    setAddress1,
    setDefaultInfo,
    setListParishes,
    setCurrentParish,
    setLoadedAutomaticInfo,
} from "./RegisterActions"
import { getRegisterStates, postRegister, putRegister } from './api/Register';
import { SnackbarContext } from '../../components/snackbar/SnackbarContext';

const modelStates = (data) => data.map(x => {
    return {
        id: x.iso_31662,
        value: x.estado,
        municipios: x.municipios,
        cuidades: x.ciudades,
    }
})
const currentSelectedState = (data, value) => data.find(x => String(x.value).toLowerCase().trim() === value.toLowerCase().trim())
const modelMunicipalities = (data) => data.map(x => {
    return {
        id: x.municipio,
        value: x.municipio
    }
})
const currentSelectedMunicipality = (data, value) => data.find(x => String(x.municipio).toLowerCase().trim() === value.toLowerCase().trim())
const modelParish = (data) => data.map(x => {
    return {
        id: x,
        value: x,
    }
})

const Register = memo(({ tgId, defaultInfo, id }) => {
    const { setLoader } = useContext(LoaderContext);
    const { mapState } = useContext(MapsStateContext);
    const [state, dispatch] = useReducer(RegisterReducer, REGISTER_INIT);
    const { sbDispatch } = useContext(SnackbarContext)

    useEffect(() => {

        const fnGetStates = async () => {
            setLoader(true);
            const responseStates = await getRegisterStates(state.currentCountry);
            if (responseStates.status === 200) {
                if (responseStates.data.errorCode === 0) {
                    const newStates = modelStates(responseStates.data.data.estados);
                    dispatch(setListStates(newStates));
                } else {
                    sbDispatch.error(responseStates.data.message)
                }
            }
            setLoader(false);
        }

        if (mapState.isMapReadyToLoad === true) {
            fnGetStates();
        }
    }, [mapState.isMapReadyToLoad])

    useEffect(() => {
        let selectedState, selectedMunicipality, listSelectedStates, listSelectedMunicipality;

        if (mapState.locationInfo.state !== "" && state.listStates.length > 0 && defaultInfo === undefined) {
            selectedState = currentSelectedState(state.listStates, mapState.locationInfo.state);
            if (selectedState) {
                dispatch(setCurrentState(selectedState.id, selectedState.value))
                const newMunicipalities = modelMunicipalities(selectedState.municipios)
                dispatch(setListMunicipalities(newMunicipalities));
                if (mapState.locationInfo.municipality !== "" && selectedState.municipios.length > 0) {
                    selectedMunicipality = currentSelectedMunicipality(selectedState.municipios, mapState.locationInfo.municipality);
                    if (selectedMunicipality) {
                        const newSelectedMunicipality = {
                            id: selectedMunicipality.municipio,
                            value: selectedMunicipality.municipio,
                        }
                        fnSetMunicipality(undefined, newSelectedMunicipality)
                        if (selectedMunicipality.parroquias) {
                            const newParishes = modelParish(selectedMunicipality.parroquias);
                            dispatch(setListParishes(newParishes));

                            // console.log(mapState.locationInfo)
                            // const newSelectedParroquia = {
                            //     id: "",
                            //     value: "",
                            // }
                            // fnSetParish(undefined, newSelectedParroquia)
                        }

                        if (mapState.locationInfo.address !== "") {
                            dispatch(setAddress1(mapState.locationInfo.address));
                        }
                    }
                }
            }

            dispatch(setLoadedAutomaticInfo());
        } else if (defaultInfo !== undefined) {

            if (state.listStates.length > 0) {
                try {
                    listSelectedStates = currentSelectedState(state.listStates, defaultInfo.estado);
                    //console.log(listSelectedStates)
                    selectedState = listSelectedStates.id
                    listSelectedMunicipality = currentSelectedMunicipality(listSelectedStates.municipios, defaultInfo.municipio)
                    defaultInfo = {
                        ...defaultInfo,
                        currentState: selectedState,
                        currentMunicipality: listSelectedMunicipality.municipio

                    }
                } catch (error) {
                    console.error('Error estableciendo data por defecto => ', error)
                }

                if (listSelectedStates) {
                    const newMunicipalities = modelMunicipalities(listSelectedStates.municipios)
                    dispatch(setListMunicipalities(newMunicipalities));
                    if (listSelectedMunicipality.parroquias) {
                        const newParishes = modelParish(listSelectedMunicipality.parroquias)
                        dispatch(setListParishes(newParishes));
                    }
                }

                dispatch(setDefaultInfo(defaultInfo))
            }
        }

    }, [state.listStates, mapState.locationInfo, defaultInfo])

    const fnSetStates = useCallback((name, value) => {
        try {
            dispatch(setCurrentState(value.id, value.value))
            const newMunicipalities = value.municipios.map(x => {
                return {
                    id: x.municipio,
                    value: x.municipio,
                    parish: (x.parroquias) ? x.parroquias : [],
                }
            })
            dispatch(setListMunicipalities(newMunicipalities));
            dispatch(setCurrentMunicipality("", ""));
        } catch (error) {
            dispatch(errorSetStates())
        }

    }, [])

    const fnSetMunicipality = useCallback((name, value) => {
        try {
            dispatch(setCurrentMunicipality(value.id, value.value))

            const newParishes = value.parish.map(x => {
                return {
                    id: x,
                    value: x,
                }
            })
            dispatch(setListParishes(newParishes));
            dispatch(setCurrentParish("", ""));
        } catch (error) {
            setCurrentMunicipality("", "");
            dispatch(setCurrentParish("", ""));
        }
    }, []);

    const fnSetParish = useCallback((name, value) => {
        try {
            dispatch(setCurrentParish(value.id, value.value))
        } catch (error) {
            setCurrentParish("", "");
        }
    }, []);

    const fnOnBlurTextField = useCallback((name, value) => dispatch(setValue(name, value)), [])

    const fnOnChange = useCallback((name, value) => {
        try {
            dispatch(setValue(name, value.id))
        } catch (error) {
            dispatch(setValue(name, ""))
        }
    }, [])

    const fnOnRegister = useCallback(async () => {
        setLoader(true)
        const response = state.isEdit === false ? await postRegister(state, mapState, tgId, id) : await putRegister(state, mapState, tgId, id);
        setLoader(false)
        if(response.status === 200){
            if(response.data.errorCode === 0){
                sbDispatch.success(response.data.message)
            }else{
                sbDispatch.error(response.data.message)
            }
        }else{
            sbDispatch.api(response.status, response.data)
        }
    }, [state, mapState, tgId, id])

    return (
        <Grid container spacing={0}>
            <Grid xs={12} sm={12} item md={12} lg={12} xl={12} align="center">
                <img src={"https://simplitstore.com/img/my-shop-logo-1528142383.jpg"} alt="SimplitStore" style={{ maxWidth: "85%", maxHeight: "85%", margin: "5px", padding: "10px" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <Grid container spacing={0} style={{ padding: "20px" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtIdSucursal"
                            name="id"
                            label="Código de sucursal"
                            fullWidth
                            value={state.id}
                            onBlur={fnOnBlurTextField} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtNameSucursal"
                            name="sucursal"
                            label="Nombre de sucursal"
                            fullWidth
                            value={state.sucursal}
                            onBlur={fnOnBlurTextField} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtService"
                            label="Servicio"
                            name="currentType"
                            options={state.listType}
                            onChange={fnOnChange}
                            value={state.currentType} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtCountry"
                            label="Pais"
                            options={state.countries}
                            value={state.currentCountry} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtState"
                            label="Estado"
                            name="currentState"
                            options={state.listStates}
                            onChange={fnSetStates}
                            value={state.currentState} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtMunicipality"
                            label="Municipio"
                            name="currentMunicipality"
                            options={state.listMunicipalities}
                            onChange={fnSetMunicipality}
                            value={state.currentMunicipality} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtParish"
                            label="Parroquia"
                            name="currentParish"
                            options={state.listParishes}
                            onChange={fnSetParish}
                            value={state.currentParish} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtAddress1"
                            name="address1"
                            label="Dirección 1"
                            fullWidth
                            value={state.address1}
                            onBlur={fnOnBlurTextField} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                        <TaTextField
                            id="txtAddress2"
                            name="address2"
                            label="Dirección 2"
                            fullWidth
                            value={state.address2}
                            onBlur={fnOnBlurTextField} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
                        <TaFab
                            id="btnPostRegister"
                            icon="save"
                            color="green"
                            onClick={fnOnRegister} />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
});

Register.propTypes = {
    tgId: PropTypes.number,
    defaultInfo: PropTypes.object,
    id: PropTypes.number,
};

export default Register;