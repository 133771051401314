import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DownloadDocument = () => {
    return (
        <SvgIcon viewBox="-110 -60 630 630">
            <path d="m112.46875 95.332031v-95.332031h-1.070312c-3.976563 0-7.792969 1.582031-10.605469 4.394531l-96.398438 96.398438c-2.8125 2.8125-4.394531 6.628906-4.394531 10.605469v1.070312h95.335938c9.445312 0 17.132812-7.6875 17.132812-17.136719zm0 0"/><path d="m336.335938 0h-193.867188v95.332031c0 25.988281-21.144531 47.132813-47.132812 47.132813h-95.335938v322.402344c0 25.988281 21.144531 47.132812 47.132812 47.132812h289.203126c25.914062 0 47.132812-20.953125 47.132812-47.132812v-417.734376c0-25.914062-20.953125-47.132812-47.132812-47.132812zm-219.476563 261.460938c5.859375-5.859376 15.355469-5.859376 21.214844 0l38.660156 38.660156v-108.386719c0-8.285156 6.714844-15 15-15s15 6.714844 15 15v108.386719l38.660156-38.660156c5.855469-5.859376 15.355469-5.859376 21.210938 0 5.859375 5.855468 5.859375 15.355468 0 21.210937l-64.265625 64.269531c-5.859375 5.859375-15.355469 5.855469-21.210938 0l-64.269531-64.269531c-5.855469-5.855469-5.855469-15.355469 0-21.210937zm139.140625 154.140624h-128.53125c-8.285156 0-15-6.71875-15-15 0-8.285156 6.714844-15 15-15h128.53125c8.285156 0 15 6.714844 15 15 0 8.28125-6.714844 15-15 15zm0 0"/>
        </SvgIcon>
    )
}

export default DownloadDocument 