import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PriceVisorIcon = () => {
    return (
        <SvgIcon>
            <path 
                className="st0" 
                d="M9.5,7.2C8.4,8.3,8.4,8.3,8.4,8.3l-4.9,4.8l0.4,0.4l6-5.9L9.5,7.2z M11.1,8.8l-6,5.9l-0.4-0.4l4.9-4.8
                c0,0,0,0,1.1-1.1L11.1,8.8z M10.4,8.1l-6,5.9l-0.2-0.2L9.1,9c0,0,0,0,1.1-1.1L10.4,8.1z M11.6,9.3l-6,5.9L5.3,15l4.9-4.8
                c0,0,0,0,1.1-1.1L11.6,9.3z M12.5,10.2l-6,5.9l-0.4-0.4l4.9-4.8c0,0,0,0,1.1-1.1L12.5,10.2z M13.7,11.4l-6,5.9l-0.4-0.4l4.9-4.8
                c0,0,0,0,1.1-1.1L13.7,11.4z M12.9,10.7l-6,5.9l-0.2-0.2l4.9-4.8c0,0,0,0,1.1-1.1L12.9,10.7z M14.1,11.9l-6,5.9l-0.2-0.2l4.9-4.8
                c0,0,0,0,1.1-1.1L14.1,11.9z M15.3,13.1l-6,5.9l-0.4-0.4l4.9-4.8c0,0,0,0,1.1-1.1L15.3,13.1z M15.8,13.6l-6,5.9l-0.2-0.2l4.9-4.8
                c0,0,0,0,1.1-1.1L15.8,13.6z M2.5,14.2l0.3,0.3l-0.1,0.1l-0.5-0.5L2.2,14c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
                c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1-0.1,0-0.1c0,0,0-0.1-0.1-0.1
                c-0.1-0.1-0.2-0.1-0.3-0.1l0.2-0.2c0.1,0,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1
                c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0C2.5,14.1,2.5,14.1,2.5,14.2
                C2.5,14.1,2.5,14.1,2.5,14.2z M2.8,14.8L3,14.6c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.1-0.2,0-0.3
                c0,0-0.1-0.1-0.2-0.1l0.5-0.4l0.4,0.4l-0.1,0.1l-0.3-0.3l-0.2,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.2
                c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.2C2.9,14.9,2.8,14.9,2.8,14.8z M4.2,15.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0
                c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
                C3.9,15,3.9,15,4,14.9c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l-0.1,0.1
                c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1l0,0c0.1,0,0.2,0,0.2,0.1
                c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1C4.3,15.7,4.3,15.7,4.2,15.7z M4.1,15.6c0.1-0.1,0.1-0.2,0-0.2c0,0,0,0-0.1,0
                c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0
                C4,15.6,4,15.6,4.1,15.6C4,15.6,4,15.6,4.1,15.6z M5.2,16.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
                c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2C4.8,16,4.9,15.9,5,15.9
                c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l-0.1,0.1c0-0.1,0-0.1-0.1-0.2
                c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1l0,0c0.1,0,0.2,0,0.2,0.1c0,0,0.1,0.1,0.1,0.1
                c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1C5.3,16.7,5.2,16.7,5.2,16.7z M5,16.6c0.1-0.1,0.1-0.2,0-0.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0
                c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0
                C4.9,16.6,4.9,16.6,5,16.6C5,16.6,5,16.6,5,16.6z M5.1,17.1l0.2-0.1c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0
                c0.1-0.1,0.1-0.2,0-0.3c0,0-0.1-0.1-0.2-0.1l0.5-0.4l0.4,0.4l-0.1,0.1l-0.3-0.3l-0.2,0.1c0,0,0.1,0.1,0.1,0.1
                c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.2C5.2,17.2,5.1,17.2,5.1,17.1z
                M5.9,17.5C5.9,17.5,5.9,17.5,5.9,17.5C6,17.5,6,17.4,6,17.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0-0.1,0-0.1,0-0.2
                c0-0.1,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
                c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
                c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1C5.8,17.6,5.9,17.6,5.9,17.5z M6.1,17.7C6,17.7,6,17.7,6.1,17.7C6,17.8,6,17.8,6,17.8
                c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
                c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0C6.2,17.6,6.1,17.6,6.1,17.7C6.1,17.6,6.1,17.7,6.1,17.7z M6.4,17.4
                C6.4,17.4,6.4,17.4,6.4,17.4C6.4,17.4,6.4,17.4,6.4,17.4c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0
                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0C6.6,17.3,6.6,17.3,6.4,17.4
                C6.5,17.3,6.5,17.3,6.4,17.4C6.5,17.3,6.5,17.3,6.4,17.4z M7.6,18.9C7.6,18.9,7.5,19,7.4,19s-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
                c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l0.1-0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l0,0
                c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0
                c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2C7.7,18.7,7.7,18.8,7.6,18.9z
                M7.5,18.6C7.6,18.6,7.6,18.5,7.5,18.6c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
                c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0
                C7.5,18.6,7.5,18.6,7.5,18.6C7.5,18.6,7.5,18.6,7.5,18.6z M8.3,19.5c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1,0.1-0.2,0.1
                c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2l0.1-0.1c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1
                c0.1,0,0.2,0,0.2-0.1l0,0c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1
                c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2
                c0,0.1,0,0.1-0.1,0.2C8.4,19.4,8.3,19.5,8.3,19.5z M8.2,19.2C8.2,19.2,8.2,19.2,8.2,19.2c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1
                c0,0,0,0,0-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
                c0,0,0,0,0.1,0c0,0,0,0,0.1,0C8.1,19.3,8.1,19.3,8.2,19.2C8.2,19.3,8.2,19.3,8.2,19.2z M8.9,20.2c-0.1,0.1-0.1,0.1-0.2,0.2
                c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1C8.1,20.2,8,20.1,8,20.1l0.1-0.1c0,0.1,0,0.1,0.1,0.2
                c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l0,0c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
                c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1s0,0.1,0,0.2
                c0,0.1,0,0.1-0.1,0.2S9,20.1,8.9,20.2z M8.8,19.9C8.9,19.9,8.9,19.9,8.8,19.9c0.1-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
                c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0
                c0,0,0,0,0.1,0C8.8,20,8.8,20,8.8,19.9C8.8,19.9,8.8,19.9,8.8,19.9z M14.1,3.7c-0.7,0-1.5,0-2.2,0c-0.4,0-0.8,0-1.2,0
                c-0.1,0-0.2,0.1-0.3,0.1c-3.3,3.3-6.6,6.6-9.9,9.9c0,0-0.1,0.1-0.1,0.1C0.1,14.2,0,14.6,0,15.1c0,0.4,0.2,0.7,0.5,0.9
                c2.2,2.2,4.4,4.4,6.6,6.6c0,0,0,0,0.1,0.1c0.3,0.3,0.6,0.4,1.1,0.4c0.5,0,0.9-0.2,1.2-0.5c2.2-2.2,4.5-4.5,6.7-6.7
                c1.1-1.1,2.1-2.1,3.2-3.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.2,0.1-0.4,0.1-0.6c0-0.8,0-1.7,0-2.5c0-0.5-0.1-1-0.1-1.4
                c0-0.4-0.1-0.9-0.1-1.3c0-0.5-0.1-0.9-0.2-1.4c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1,0-0.2-0.1-0.3-0.1
                c-0.3,0-0.6-0.1-0.9-0.1c-0.1,0-0.3,0-0.4,0c0,0.2,0,0.3,0,0.5c0,0.4,0.1,0.7,0.1,1.1c0,0.1,0,0.1,0.1,0.1c0.7,0.5,1,1.5,0.6,2.3
                c-0.3,0.7-0.8,1-1.6,1.1C15.5,9,15,8.8,14.6,8.4C14,7.7,14,6.8,14.4,6.1c0,0,0-0.1,0-0.1c0-0.3-0.1-0.6-0.1-0.9
                c-0.1-0.4-0.1-0.8-0.1-1.1c0-0.1,0-0.2,0-0.3C14.2,3.7,14.2,3.7,14.1,3.7z M23,3.4c-0.2-0.7-0.5-1.4-0.9-2c-0.7-0.9-1.6-1.4-2.8-1.4
                c-0.6-0.1-1.3,0-1.9,0.3c-0.9,0.4-1.5,0.9-2,1.8c-0.3,0.5-0.5,1.1-0.6,1.7c0,0.4,0,0.8,0.1,1.2c0.1,0.8,0.3,1.5,0.4,2.3
                c0.1,0.4,0.4,0.6,0.8,0.5c0.4-0.1,0.6-0.5,0.5-0.8c-0.1-0.4-0.2-0.8-0.2-1.2c-0.1-0.4-0.1-0.8-0.2-1.1c0-0.3-0.1-0.7,0-1
                c0.2-0.9,0.6-1.6,1.4-2c0.6-0.3,1.2-0.4,1.8-0.3c0.7,0.1,1.2,0.4,1.6,1c0.4,0.6,0.6,1.2,0.6,1.9c0,0.8-0.1,1.6-0.2,2.3
                c-0.2,1.2-0.6,2.3-1.2,3.3c-0.1,0.2-0.1,0.5,0,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.2,0.5-0.4c0.3-0.5,0.5-1.1,0.7-1.6
                c0.5-1.4,0.8-2.9,0.8-4.5C23.1,4.2,23.1,3.8,23,3.4z"/>
        </SvgIcon>
    )
}

export default PriceVisorIcon