import React, { createContext, useReducer } from "react";
import {
    MAPS_INITIALSTATE,
} from "./MapsContants";

import MapsReducer from "./MapsReducer"

const MapsStateContext = createContext(MAPS_INITIALSTATE);
const MapsDispatchContext = createContext();

const MapsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(MapsReducer, MAPS_INITIALSTATE);
    return (
        <MapsDispatchContext.Provider value={{ mapDispatch: dispatch }}>
            <MapsStateContext.Provider value={{ mapState: state }}>
                {children}
            </MapsStateContext.Provider>
        </MapsDispatchContext.Provider>
    );
};

export { MapsDispatchContext, MapsStateContext, MapsProvider };