import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
    TextField,
} from '@material-ui/core/';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';

const TextFieldTheme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                marginTop: "10px",
                marginBottom: "10px",
                padding: "5px",
                fontSize: "12pt",
                //fontFamily: "Archivo",
                height: "40px",
                '&$hover': {
                    borderBottom: '1.5px solid var(--main-bg-color3)',
                },
            },
        },
        MuiInputLabel: {
            root: {
                fontSize: "10pt",
                //fontFamily: "Archivo",
                '&$focused': {
                    color: 'var(--main-bg-color3)',
                },
            }
        },
        MuiInput: {
            underline: {
                '&:after': {
                    borderBottom: '2px solid var(--main-bg-color3)',
                }
            }
        }
    },
});

const TaTextField = React.memo((
    { id, value, name,
        label, type, placeholder,
        helperText, decimalScale, style,
        fullWidth, onBlur, align, onChange,
        options, disabled },
) => {
    const [val, setVal] = useState('');
    useEffect(() => {
        if (options) {
            if (value !== undefined && value !== "") {
                const currentOption = options.find(x => x.id === value)
                setVal(currentOption === undefined ? "" : currentOption.value);
            }else{
                setVal("");
            }
        } else {
            setVal(value);
        }

    }, [value, options]);

    const fnOnBlur = useCallback((event) => {
        if (onBlur) {
            onBlur(event.target.name, event.target.value)
        }
    }, [])

    const fnOnKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }, [])

    const fnSetValue = useCallback((event, event2) => {
        let currentValue = (event2) ? event2.value : event.target.value
        currentValue = (currentValue) ? currentValue : "";
        setVal(currentValue);
        if (onChange) {
            if (options) {
                onChange(name, event2)
            } else {
                onChange(name, currentValue)
            }

        }
    }, [name, options])

    return (
        <ThemeProvider theme={TextFieldTheme}>
            {(options)
                ? <Autocomplete
                    id={id}
                    options={options}
                    getOptionLabel={(option) => option.value}
                    onChange={fnSetValue}
                    inputValue={val}
                    autoComplete
                    disabled={disabled}
                    renderInput={(params) => <TextField
                        {...params}
                        label={label}
                        onChange={fnSetValue} />
                    } />
                : <TextField
                    id={id}
                    value={val}
                    name={name}
                    label={label}
                    type={type}
                    placeholder={placeholder}
                    helperText={helperText}
                    fullWidth={fullWidth && fullWidth}
                    onChange={fnSetValue}
                    onKeyDown={fnOnKeyDown}
                    onBlur={fnOnBlur}
                    autoComplete={"new-password"}
                    inputProps={{ style: { textAlign: align } }}
                    disabled={disabled} />
            }
        </ThemeProvider>

    );
});

TaTextField.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    decimalScale: PropTypes.number,
    style: PropTypes.object,
    align: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

TaTextField.defaultProps = {
    name: '',
    label: '',
    type: 'text',
    placeholder: '',
    decimalScale: 2,
    align: 'left',
    disabled: false,
};

export default TaTextField;