import React, { useContext } from "react";
import PropTypes from 'prop-types';
import {
    LoaderContext,
} from "./LoaderContext";

import {
    CircularProgress,
    Dialog,
    DialogContent,
} from '@material-ui/core/';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const LoaderTheme = createMuiTheme({
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: "transparent",
            },
            elevation: {
                boxShadow: 'none',
            }
        },
    },
});

const Loader = () => {
    const { loaderState } = useContext(LoaderContext);

    return (
        <ThemeProvider theme={LoaderTheme}>
            <Dialog open={loaderState} >
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        </ThemeProvider>

    );
};

export default Loader;