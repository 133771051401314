import React from 'react';
import PropTypes from 'prop-types';
import Main from './views/Main';
import { MapsProvider } from './components/Maps/MapsContext';
import { LoaderProvider } from './components/Loader/LoaderContext';
import {
    SnackbarProvider,
} from "./components/snackbar/SnackbarContext"

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import { QueryParamProvider } from 'use-query-params';

import Loader from './components/Loader/Loader';
import TaSnackbar from "./components/snackbar/Snackbar"

const App = props => {
    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
                <SnackbarProvider>
                    <LoaderProvider>
                        <MapsProvider>
                            <Switch>
                                <Route exact path="/">
                                    <Main />
                                </Route>
                            </Switch>
                            <Loader />
                            <TaSnackbar />
                        </MapsProvider>
                    </LoaderProvider>
                </SnackbarProvider>
            </QueryParamProvider>

        </Router>
    );
};

export default App;