export const REGISTER_INIT = {
    tgId: 0,
    id: "",
    sucursal: "",
    countries: [
        {
            id: "VE",
            value: "Venezuela",
        }
    ],
    currentCountry: "VE",
    listStates: [],
    currentState: "",
    nameState: "",
    listMunicipalities: [],
    currentMunicipality: "",
    nameMunicipality: "",
    address1: "",
    address2: "",
    listType: [
        {
            id: "0",
            value: "PickUp"
        },
        {
            id: "1",
            value: "Delivery"
        },
        {
            id: "2",
            value: "PickUp y Delivery"
        }
    ],
    currentType: "",
    listParishes: [],
    currentParish: "",
    nameParish: "",
    isEdit: false,
    isLoadedAutomaticInfo: false,
}
export const REGISTER_SETVALUE = 'REGISTER_SETVALUE'
export const REGISTER_ERRORSETSTATES = 'REGISTER_ERRORSETSTATES'
export const REGISTER_DEFAULTINFO = 'REGISTER_DEFAULTINFO'
export const REGISTER_SETSTATE = 'REGISTER_SETSTATE'
export const REGISTER_SETMUNICIPALITY = 'REGISTER_SETMUNICIPALITY'
export const REGISTER_SETPARISH = 'REGISTER_SETPARISH'