import {
    REGISTER_INIT,
    REGISTER_SETVALUE,
    REGISTER_ERRORSETSTATES,
    REGISTER_DEFAULTINFO,
    REGISTER_SETSTATE,
    REGISTER_SETMUNICIPALITY,
    REGISTER_SETPARISH,
} from "./RegisterConstants"

const RegisterReducer = (state, action) => {
    switch (action.type) {
        case REGISTER_SETVALUE:
            return {
                ...state,
                [action.id]: action.value
            }
        case REGISTER_ERRORSETSTATES:
            return {
                ...state,
                currentState: "",
                nameState: "",
                listMunicipalities: [],
                currentMunicipality: "",
                nameMunicipality: "",
                listParishes: [],
                currentParish: "",
                nameParish: "",
            }
        case REGISTER_DEFAULTINFO:

            const { codSucu, sucursal, pais, estado, municipio, direccion1, direccion2, tgId, tipo, parroquia,
                currentState, currentMunicipality } = action.data
            return {
                ...state,
                id: codSucu,
                sucursal: sucursal,
                currentCountry: pais,
                nameState: estado,
                nameMunicipality: municipio,
                address1: direccion1,
                address2: direccion2,
                nameParish: parroquia,
                tgId: tgId,
                currentType: String(tipo),
                isEdit: true,
                currentState: currentState,
                currentMunicipality: currentMunicipality,
                currentParish: parroquia,

            }
        case REGISTER_SETSTATE:
            return {
                ...state,
                currentState: action.id,
                nameState: action.value,
            }
        case REGISTER_SETMUNICIPALITY:
            return {
                ...state,
                currentMunicipality: action.id,
                nameMunicipality: action.value,
            }
        case REGISTER_SETPARISH:
            return {
                ...state,
                currentParish: action.id,
                nameParish: action.value,
            }
        default:
            return state;
    }
}

export default RegisterReducer