import { fnGetUrl, fnPostUrl, fnPutUrl } from '../../../functions/Api';
export const getUser = async () => {
    const apiRequest = {
        useBasic: true,
        endpoint: ''
    }
    return fnGetUrl(apiRequest)
}
export const getUserDetail = async (id) => {
    const apiRequest = {
        useBasic: true,
        endpoint: ''
    }
    return fnGetUrl(apiRequest)
}
export const putUser = async (id, data) => {
    const request = {

    }
    const apiRequest = {
        useBasic: true,
        endpoint: '',
        body: request
    }
    return fnPutUrl(apiRequest)
}
export const postUser = async (data) => {
    const request = {

    }
    const apiRequest = {
        useBasic: true,
        endpoint: '',
        body: request
    }
    return fnPostUrl(apiRequest)
}