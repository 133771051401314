import { fnGetUrl, fnPostUrl, fnPutUrl } from '../../../functions/Api';
export const getRegisterStates = async (countryId) => {
    const apiRequest = {
        useBasic: true,
        endpoint: "api/Ubic/Pais_Estados?sPais=" + countryId,
    }
    return fnGetUrl(apiRequest)
}

export const postRegister = async (register, map, tgid, id) => {
    const request = RegisterModel(register, map, tgid, id);
    const apiRequest = {
        useBasic: true,
        endpoint: 'api/Admin/Sucu',
        body: request
    }
    return fnPostUrl(apiRequest)
}

export const putRegister = async (register, map, tgid, id) => {

    const request = RegisterModel(register, map, tgid, id);
    const apiRequest = {
        useBasic: true,
        endpoint: 'api/Admin/Sucu/id/'+id,
        body: request
    }
    return fnPutUrl(apiRequest)
}

const RegisterModel = (register, map, tgid, id) => {
    return {
        id: id,
        tgId: tgid,
        codSucu: register.id,
        sucursal: register.sucursal,
        longitud: map.location.lng,
        latitud: map.location.lat,
        pais: register.currentCountry,
        estado: register.nameState,
        municipio: register.nameMunicipality,
        parroquia: register.nameParish,
        direccion1: register.address1,
        direccion2: register.address2,
        tipo: +register.currentType,
        poligonal: map.polygones.map(x => {
            return {
                longitud: x.lng,
                latitud: x.lat,
            }
        })
    }
}
