import {
    REGISTER_INIT,
    REGISTER_SETVALUE,
    REGISTER_ERRORSETSTATES,
    REGISTER_DEFAULTINFO,
    REGISTER_SETSTATE,
    REGISTER_SETMUNICIPALITY,
    REGISTER_SETPARISH,
} from "./RegisterConstants"

export const setValue = (id, value) => ({ type: REGISTER_SETVALUE, id, value })
export const setListStates = (value) => ({ type: REGISTER_SETVALUE, id: "listStates", value })
export const setListMunicipalities = (value) => ({ type: REGISTER_SETVALUE, id: "listMunicipalities", value })
export const setCurrentState = (id, value) => ({ type: REGISTER_SETSTATE, id, value })
export const setCurrentMunicipality = (id, value) => ({ type: REGISTER_SETMUNICIPALITY, id, value })
export const errorSetStates = () => ({ type: REGISTER_ERRORSETSTATES })
export const setAddress1 = (value) => ({ type: REGISTER_SETVALUE, id: "address1", value })
export const setDefaultInfo = (data) => ({ type: REGISTER_DEFAULTINFO, data })
export const setListParishes = (value) => ({ type: REGISTER_SETVALUE, id: "listParishes", value })
export const setCurrentParish = (id, value) => ({ type: REGISTER_SETPARISH, id, value })
export const setLoadedAutomaticInfo = () => ({ type: REGISTER_SETVALUE, id: "isLoadedAutomaticInfo", value: true })
