import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    List,
    ListItem,
} from "@material-ui/core"

import {
    makeStyles,
} from '@material-ui/core/styles'

import TaTypography from "../../../components/typography/TaTypography"

const ListItemStyle = makeStyles({
    itemList: {
        height: '40px',
        backgroundColor: "var(--main-list-color0)",
        marginTop: "5px",
        marginBottom: "5px",
        padding: "5px",
        borderRadius: "5px",
        cursor: "pointer" 
    }
})

const UserList = memo(({ data, onClick }) => {
    const classes = ListItemStyle();
    return (
        <List
            component="nav">
            {
                data.map(user => {
                    return (
                        <ListItem
                            key={user.uniqueKey}
                            data-id={user.id}
                            className={classes.itemList}
                            onClick={onClick}>
                            <TaTypography text={`${user.name}`} />
                        </ListItem>
                    )
                })
            }
        </List>

    );
});

UserList.propTypes = {
    data: PropTypes.array,
};

UserList.defaultProps = {
    data: [],
    onClick: () => { }
}

export default UserList;