import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PrinterPos = () => {
    return (
        <SvgIcon>
        <path className="st0" 
            d="M18 10H6A2 2 0 0 0 4 12V19H20V12A2 2 0 0 0 18 10M18 14H14V12H18M17 9H7V4H17Z" />
        </SvgIcon>
    )
}

export default PrinterPos