import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    List,
    ListItem,
} from "@material-ui/core"

import {
    makeStyles,
} from '@material-ui/core/styles'

import TaTypography from "../../../components/typography/TaTypography"
import TaCheckBox from "../../../components/checkbox/TaCheckBox"

const ListItemStyle = makeStyles({
    itemList: {
        height: '40px',
        backgroundColor: "var(--main-list-color0)",
        marginTop: "5px",
        marginBottom: "5px",
        padding: "5px",
        borderRadius: "5px",
        cursor: "pointer",
    }
})

const OfficeListDetail = memo(({ classes, uniqueKey, active, onClick, name }) => {
    console.log(uniqueKey)
    return (
        <ListItem className={classes.itemList}>
            <Grid container alignContent="flex-start" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TaCheckBox
                        id={`cbxActive-${uniqueKey}`}
                        name="active"
                        checked={active}
                        onChange={onClick}
                        uniqueKey={uniqueKey}
                        label={<TaTypography text={`${name}`} />} />
                </Grid>
            </Grid>
        </ListItem>
    )
});

const OfficeList = memo(({ data, onClick }) => {
    const classes = ListItemStyle();
    return <List component="nav">{data.map(office => <OfficeListDetail key={office.uniqueKey} classes={classes} onClick={onClick} {...office}></OfficeListDetail>)}</List>
});

OfficeList.propTypes = {
        data: PropTypes.array,
    };

OfficeList.defaultProps = {
    data: [],
    onClick: () => { }
}

export default OfficeList;