export const MAPS_INITIALSTATE = {
    isMapReadyToLoad: false,
    location: {
        lat: 0,
        lng: 0,
    },
    width: "300px",
    height: "300px",
    markers: [],
    polygones: [],
    locationInfo: {
        country: "",
        state: "",
        municipality: "",
        city: "",
        address: "",
        addressReference: "",
    },
    optionMenu: [],
    selectedOptionMenu: 0,
};
export const MAPS_INITLOCATION = 'MAPS_INITLOCATION';
export const MAPS_ADDMARKERS = 'MAPS_ADDMARKERS';
export const MAPS_REMMARKERS = 'MAPS_REMMARKERS';
export const MAPS_ADDPOLYGON = 'MAPS_ADDPOLYGON';
export const MAPS_SETLOCATIONINFO = 'MAPS_SETLOCATIONINFO';
export const MAPS_SETVALUE = 'MAPS_SETVALUE';
export const MAPS_SETDEFAULTINFO = 'MAPS_SETDEFAULTINFO';
export const MAPS_REMPOLYGON = 'MAPS_REMPOLYGON';
export const MAPS_SETOPTIONMENU = 'MAPS_SETOPTIONMENU'
export const MAPS_SETOPTIONMENUVALUE = 'MAPS_SETOPTIONMENUVALUE'