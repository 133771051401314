import React, { createContext, useState } from "react";

const LoaderContext = createContext(false);

const LoaderProvider = ({ children }) => {
    const [loaderState, setLoader] = useState(false);
    return (
        <LoaderContext.Provider value={{ loaderState, setLoader }}>
            {children}
        </LoaderContext.Provider>
    );
};

export { LoaderContext, LoaderProvider };