import React, { memo } from 'react';

import {
    Dialog,
    DialogContent,
} from '@material-ui/core/';
import TaTypography from '../typography/TaTypography';

const Unauthorized = memo(() => {
    return (
        <Dialog open={true} >
            <DialogContent>
                <TaTypography text="Token invalido" color="var(--main-bg-color1)" fontSize="20pt" />
            </DialogContent>
        </Dialog>
    );
});

export default Unauthorized;