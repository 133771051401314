import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    makeStyles,
} from '@material-ui/core/styles'

import {
    Typography,
} from "@material-ui/core/"

const TaTypographyStyles = makeStyles({
    typography: (props) => ({
        margin: "2px",
        fontSize: props.fontSize,
        color: props.color,
        height: props.height,
        width: props.width
    }),
});

const TaTypography = memo(({ fontSize, type, color, text, align, width, height, display }) => {
    const classes = TaTypographyStyles({fontSize: fontSize, color: color, width: width, height: height})
    return <Typography align={align} className={classes.typography} display={display}>{text}</Typography>
});

TaTypography.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    text: PropTypes.any,
    display: PropTypes.string,
};

TaTypography.defaultProps = {
    fontSize: "14pt",
    type: "regular",
    color: "var(--main-text-color0)",
    align: "left",
    display: "initial",
};

export default TaTypography;